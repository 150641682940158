<template>
    <div class="com-filter-pannel">
        <div class="com-filter-item-tab row row-area clearfix">
            <span class="left">按区域：</span>
            <div class="content">
                <div class="common-tab clearfix">
                    <div class="content">
                        <div class="item active" style="padding-top: 0;">
                            <div class="clearfix">
                                <ul class="filter-list q"  v-if="city">
                                    <li :class="{'active': !filter.area_id}">
                                        <a @click="changeArea(0)">不限</a>
                                    </li>

                                    <li v-for="area in city.c" :key="area.v"
                                            :class="{'active': filter.area_id === area.v}"
                                            @click="changeArea(area.v)">
                                        <a :title="area.n">{{area.n}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row row-price clearfix">
            <span class="left">驾照类型：</span>
            <div class="content">
                <ul class="clearfix">
                    <li><a class="active">不限</a></li>
                    <li><a>C1</a></li>
                    <li><a>C2</a></li>
                    <li><a>B1/B2</a></li>
                    <li><a>A1/A2</a></li>
                    <li><a>D/E/F</a></li>
                </ul>
            </div>
        </div> -->
        <div class="row row-price clearfix">
            <span class="left">价格区间：</span>
            <div class="content">
                <ul class="clearfix">
                    <li v-for="(item, index) in prices" :key="index">
                        <a @click="changePrice(item.value)" :class="{'active': filter.charge === item.value}">
                            {{item.label}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row row-order clearfix">
            <span class="left">排序方式：</span>
            <div class="content">
                <ul class="clearfix">

                    <li>
                        <a :class="{'active': filter.orderby === ''}" @click="changeOrderby('', 1)">
                            排行
                        </a>
                    </li>
                    <li>
                        <a :class="{'active': filter.orderby === 'rating'}" @click="changeOrderby('rating', 1)">
                            口碑
                        </a>
                    </li>
                    <li>
                        <a class="price" :class="priceOrderClass"
                                @click="changeOrderby('charge', !filter.desc)">
                            价格
                        </a>
                    </li>

                    <!-- <li class="search-bar">
                        <form method="get">
                            <input type="text" name="s" value="" placeholder="精确查找">
                            <input type="hidden" name="cityCode" value="110000">
                            <button type="submit"></button>
                        </form>
                    </li> -->
                </ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, toRefs, Ref, inject } from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({

    components: {

    },

    props: {
        city_id: Number
    },

    setup: () => {
        const filter = inject('school-filter');

        return {
            filter
        };
    },

    data: function () {

        return {
            prices: [
                {label: '不限', value: ''},
                {label: '￥3000以下', value: '-3000'},
                {label: '￥3000-￥4000', value: '3000-4000'},
                {label: '￥4000-￥5000', value: '4000-5000'},
                {label: '￥5000以上', value: '5000-'},
            ],

            orderFields: [
                {label: '排行', value: ''},
                {label: '口碑', value: 'rating'},
                {label: '价格', value: 'charge', desc: 0},
            ]
        };
    },

    computed: {
        ...mapGetters('app', ['cityMap']),

        city: function () {

            if (this.city_id) {
                return this.cityMap[this.city_id];
            }

            return null;
        },

        priceOrderClass: function () {
            let classNames = [];

            if (this.filter.orderby === 'charge') {

                if (this.filter.desc) {
                    classNames.push('desc');
                }
                else {
                    classNames.push('des');
                }
            }

            return classNames;
        }
    },

    methods: {
        changeArea: function (area_id) {
            this.filter.area_id = area_id;
        },

        changePrice: function (charge) {
            this.filter.charge = charge;
        },

        changeOrderby: function (field, desc) {
            this.filter.orderby = field;
            this.filter.desc = Number(desc);
        }
    }
});
</script>

<style scoped lang="scss">
.filter-list {
    li {
        margin-top: 0;
    }
}
</style>