
import { defineComponent, toRefs, Ref, inject } from 'vue';
import {mapGetters} from 'vuex';

export default defineComponent({

    components: {

    },

    props: {
        city_id: Number
    },

    setup: () => {
        const filter = inject('school-filter');

        return {
            filter
        };
    },

    data: function () {

        return {
            prices: [
                {label: '不限', value: ''},
                {label: '￥3000以下', value: '-3000'},
                {label: '￥3000-￥4000', value: '3000-4000'},
                {label: '￥4000-￥5000', value: '4000-5000'},
                {label: '￥5000以上', value: '5000-'},
            ],

            orderFields: [
                {label: '排行', value: ''},
                {label: '口碑', value: 'rating'},
                {label: '价格', value: 'charge', desc: 0},
            ]
        };
    },

    computed: {
        ...mapGetters('app', ['cityMap']),

        city: function () {

            if (this.city_id) {
                return this.cityMap[this.city_id];
            }

            return null;
        },

        priceOrderClass: function () {
            let classNames = [];

            if (this.filter.orderby === 'charge') {

                if (this.filter.desc) {
                    classNames.push('desc');
                }
                else {
                    classNames.push('des');
                }
            }

            return classNames;
        }
    },

    methods: {
        changeArea: function (area_id) {
            this.filter.area_id = area_id;
        },

        changePrice: function (charge) {
            this.filter.charge = charge;
        },

        changeOrderby: function (field, desc) {
            this.filter.orderby = field;
            this.filter.desc = Number(desc);
        }
    }
});
