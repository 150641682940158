
import { defineComponent, toRefs, Ref } from 'vue';

export default defineComponent({

    components: {

    },

    props: {
        schools: Array
    }
});
