
import { defineComponent, toRefs, Ref, ref, provide } from 'vue';
import {mapState} from 'vuex';

import BreadCrumbs from '@/components/widgets/bread-crumbs.vue';
import SchoolList from '@/components/school/school-list.vue';
import FilterPanel from '@/components/school/filter-panel.vue';
import Pagination from '@/components/widgets/pagination.vue';

import {useSchoolList, useSchoolListFilter} from '@/compositions/training-school';

export default defineComponent({

    components: {
        BreadCrumbs,
        SchoolList,
        FilterPanel,
        Pagination
    },

    props: {
        page: Number
    },

    setup: (props) => {
        const page = ref(props.page);

        const filter = useSchoolListFilter(page as Ref<number>);
        const schoolListSetup = useSchoolList(page as Ref<number>, filter);

        provide('school-filter', filter);

        return {
            ...schoolListSetup,
            filter
        };
    },

    computed: {
        ...mapState('app', ['city_id'])
    }
});
