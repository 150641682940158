<template>

    <div class="com-school-list com-part">
        <ul>
            <li class="clearfix" v-for="school in schools" :key="school.id">
                <router-link class="left img-w" :to="{name: 'school-detail', query: {id: school.id}}">
                    <img :src="school.images[0]" :alt="school.name">
                    <div class="tip-bar">
                        <!-- <span class="renzheng"></span> -->
                    </div>
                </router-link>
                <div class="item-mid left">
                    <p class="top-bar">
                        <router-link class="title" :to="{name: 'school-detail', query: {id: school.id}}">
                            {{school.name}}
                        </router-link>
                    </p>
                    <p class="field">{{school.address}}</p>
                    <!-- <p class="crumbs">
                        <a>北京市驾校</a>
                        <i>&gt;</i><a>北京驾校</a>
                        <i>&gt;</i><span>北方驾校</span>
                    </p> -->
                    <div class="address">
                        <span class="score">
                            <div class="star-w-s">
                                <div class="bfb" :style="{width: (school.rating / 5) + '%'}"></div>
                            </div>
                            {{school.rating}}分
                        </span>
                        <span class="student">{{school.students}}名学员</span>
                    </div>
                    <!-- <div class="address"><a>共0条评论</a></div> -->
                </div>
                <div class="item-right right"><span class="price">￥{{school.charge}}</span></div>
            </li>
        </ul>
    </div>

</template>

<script lang="ts">
import { defineComponent, toRefs, Ref } from 'vue';

export default defineComponent({

    components: {

    },

    props: {
        schools: Array
    }
});
</script>